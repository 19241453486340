import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const getDataDate = async (startDateTime, endDateTime) => {
    const start = startDateTime;
    const end = endDateTime;

    // convert the "2022-09-17T12:56" to "2022-09-17 12:56:00"
    const startDateTimeFormat = start.replace("T", " ");
    const endDateTimeFormat = end.replace("T", " ");

    try {
        const result = await axios.get(`${baseURL}/getDataMysqlByTime`, {
            params: {
                start: startDateTimeFormat,
                end: endDateTimeFormat
            }
        });
        return result.data;
    } catch (error) {
        console.log(error);
    }
};