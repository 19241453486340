import React, { useEffect, useState } from "react";
import { getDataSensorId } from "../../api/sensor";
import SensorItem from "../../component/sensor";

export default function Sensor() {
 const [data, setData] = useState();
 const [sensorId, setSensorId] = useState("1");
 const getDataSensor = async (res) => {
  const result = await getDataSensorId(sensorId);
  setData(result);
  console.log(data.data[1][0]);
 };

 useEffect(() => {
  getDataSensor();
  window.localStorage.setItem("sensorId", sensorId);
 }, []);

 return (
  <>
   <div className="h-screen flex bg-gray-bg1">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16">
     {/* A for loop for 6 sensors */}
     <h2>Sensor 1 </h2>
     <br />
     <button
      className={`bg-primary py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark`}
      type="submit"
      onClick={() => {
       window.location.href = "/sensor/plot";
      }}
     >
      Plot
     </button>
     <button
      className={`bg-primary py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark`}
      type="submit"
      onClick={() => {
       window.location.href = "/sensor/table";
      }}
     >
      Table
     </button>
     <button
      className={`bg-primary py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark`}
      type="submit"
      onClick={() => {
       window.location.href = "/sensor/message";
      }}
     >
      Message
     </button>
     <button
      className={`bg-primary py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark`}
      type="submit"
      onClick={() => {
       window.location.href = "/sensor/message";
      }}
     >
      Prediction
     </button>
    </div>
   </div>
  </>
 );
}
