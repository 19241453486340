import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";

export default function Login() {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

 const onSuccess = (res) => {
  console.log("You are logged In");
  window.location.href = "/sensor";
 };
 const onFailure = (res) => {
  console.log("login failed");
  window.location.href = "/sensor";
 };
 const handleSubmit = async (e) => {
  e.preventDefault();
  console.log(email);
  if (password === "test") {
   window.alert("Hi, Vibhanshu, welcome back.");
   window.localStorage.setItem("auth", true);
   window.location.href = "/sensor";
   setEmail("");
   setPassword("");
  } else {
   window.alert("Wrong username or password");
  }
 };
 return (
  <>
   <div className="h-screen flex bg-gray-bg1">
    <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16">
     <h2 className="text-2xl font-medium text-primary mt-4 mb-12 text-center">
      Login
     </h2>
     <form onSubmit={handleSubmit}>
      <div>
       {/* <div className="text-center">
        <GoogleLogin
         clientId="312232375517-jj6vk0aeigmnhas9hv9qmd3kgdlhlik8.apps.googleusercontent.com"
         buttonText="Login"
         onSuccess={onSuccess}
         onFailure={onFailure}
         cookiePolicy={"single_host_origin"}
         isSignedIn={true}
        />
       </div>
       <br />
       <hr class="border-1 border-blue cursor-pointer hover:border-white duration-500 width-20" />
       <br /> */}
       <label htmlFor="email">Email</label>
       <input
        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
       />
      </div>
      <div>
       <label htmlFor="password">Password</label>
       <input
        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
       />
      </div>
      <div>
       <button
        className={`bg-primary py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark`}
        type="submit"
       >
        Login
       </button>
      </div>
     </form>
    </div>
   </div>
  </>
 );
}
