import React from "react";
import "./style.css";

export default function LandingDashBoard() {
 return (
  <>
   <div className="h-screen flex bg-gray-bg1">
    <div className="w-full text-center max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16">
     <h2>Welcome to Aquaculture Application</h2>
     <br />
     <button class="bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
      <a href="/login">Login</a>
     </button>
    </div>
   </div>
  </>
 );
}
