import LandingDashBoard from "../pages/home";
import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import Sensor from "../pages/sensor";
import Plot from "../pages/sensor/plot";
import Table from "../pages/sensor/table";
import Message from "../pages/sensor/message";
import Prediction from "../pages/sensor/prediction";

export default function Routing() {
 const [isAuthenticated, setIsAuthenticated] = React.useState(false);

 React.useEffect(() => {
  setIsAuthenticated(window.localStorage.getItem("auth"));
  console.log(isAuthenticated);
 }, [isAuthenticated]);
 return (
  <>
   <BrowserRouter>
    <Routes>
     <Route path="/" element={<LandingDashBoard />} />
     {/* auth routing */}
     <Route path="/login" element={<Login />} />

     {/* sensor routing */}
     {isAuthenticated && (
      <>
       <Route path="/sensor" element={<Sensor />} />
       <Route path="/sensor/plot" element={<Plot />} />
       <Route path="/sensor/table" element={<Table />} />
       <Route path="/sensor/message" element={<Message />} />
       <Route path="/sensor/prediction" element={<Prediction />} />
      </>
     )}
    </Routes>
   </BrowserRouter>
  </>
 );
}
