import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

async function getDataSensorId(sensorId) {
    console.log(baseURL, sensorId);
    try {
        const response = await axios.post(`${baseURL}/getData?sensorID=${sensorId}`);
        // console.log(response);
        return response;
    } catch (error) {
        window.alert("Some technical error at our end");
        console.log(error);
    }
}

export { getDataSensorId };