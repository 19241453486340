import React, { useEffect, useState } from "react";
import { getDataDate } from "../../../api/getData";

export default function Message() {
 const [showNumber, setShowNumber] = useState(10);
 const [data, setData] = useState([]);
 const [startDateTime, setStartDateTime] = useState("");
 const [endDateTime, setEndDateTime] = useState("");

 //  get the data from the api using the start and end date.
 const getData = async () => {
  const data = await getDataDate(startDateTime, endDateTime);
  setData(data);
  console.log(data);
 };

 return (
  <>
   <div className="h-screen flex bg-gray-bg1 p-5 m-5">
    {/* A for loop for 6 sensors */}
    {/* heading */}
    <div>
     <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
      Message
     </h4>
     {/* The date and time selections */}
     <div className="flex flex-row">
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Start Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={startDateTime}
        onChange={(e) => {
         setStartDateTime(e.target.value);
         getData();
        }}
       />
      </div>
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        End Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={endDateTime}
        onChange={(e) => {
         setEndDateTime(e.target.value);
         getData();
        }}
       />
      </div>
     </div>
     <div>
      <br />
      <label for="number">Choose a number of data to show : &nbsp;</label>
      <select
       name="number"
       id="number"
       className="border border-grey-light text-grey-darkest rounded-lg"
       onChange={(e) => {
        setShowNumber(e.target.value);
       }}
      >
       <option value="10">10</option>
       <option value="20">20</option>
       <option value="50">50</option>
       <option value="100">100</option>
       <option value="500">500</option>
      </select>
     </div>
     <br />

     {/* Table for the data */}
     <div class="bg-white shadow-md rounded my-6">
      <table class="min-w-max w-full table-auto">
       <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
         <th class="py-3 px-6 text-left">Date & Time</th>
         <th class="py-3 px-6 text-left">Temperature</th>
         <th>Message</th>
        </tr>
       </thead>
       <tbody class="text-gray-600 text-sm font-light">
        {/* Show only if start date and endDate is not empty */}
        {startDateTime && endDateTime ? (
         <>
          {data?.slice(0, showNumber).map((item) => (
           <tr class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-6 text-left whitespace-nowrap">
             <div class="flex items-center">
              <span class="font-medium">{item.dateTime}</span>
             </div>
            </td>

            <td class="py-3 px-6 text-left">
             <div class="flex items-center">
              <span>{item.temperature}</span>
             </div>
            </td>
            <td class="py-3 px-6 text-left">
             <div class="flex items-center">
              <span>
               {item.ammonia} + {item.temperature}
              </span>
             </div>
            </td>
           </tr>
          ))}
         </>
        ) : (
         <div></div>
        )}
       </tbody>
      </table>
     </div>
    </div>
   </div>
  </>
 );
}
