import React, { useEffect, useState } from "react";
import { getDataDate } from "../../../api/getData";
import "./style.css";
// The chart js library
import {
 Chart as ChartJS,
 CategoryScale,
 LinearScale,
 PointElement,
 LineElement,
 Title,
 Tooltip,
 Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
 CategoryScale,
 LinearScale,
 PointElement,
 LineElement,
 Title,
 Tooltip,
 Legend
);

export const options = {
 responsive: true,
 interaction: {
  mode: "index",
  intersect: false,
 },
 stacked: false,
 plugins: {
  title: {
   display: true,
   text: "Chart.js Line Chart - Multi Axis",
  },
 },
 scales: {
  y: {
   type: "linear",
   display: true,
   position: "left",
  },
  y1: {
   type: "linear",
   display: true,
   position: "right",
   grid: {
    drawOnChartArea: false,
   },
  },
 },
};

// export const options = {
//  responsive: true,
//  plugins: {
//   legend: {
//    position: "top",
//   },
//   title: {
//    display: true,
//    text: "Chart.js Line Chart",
//   },
//  },
// };

export default function Plot() {
 const [showNumber, setShowNumber] = useState(10);
 const [sensor, setSensor] = useState([]);
 const [data, setData] = useState([]);
 const [startDateTime, setStartDateTime] = useState("");
 const [endDateTime, setEndDateTime] = useState("");

 const [temperature, setTemperature] = useState(true);
 const [ammonia, setAmmonia] = useState(false);
 const [chlorine, setChlorine] = useState(false);
 const [nitrite, setNitrite] = useState(false);
 const [nitrate, setNitrate] = useState(false);
 const [ph, setPh] = useState(false);
 const [turbidity, setTurbidity] = useState(false);
 const [dissolvedOxygen, setDissolvedOxygen] = useState(false);
 const [tds, setTds] = useState(false);
 const [orp, setOrp] = useState(false);

 //  get the data from the api using the start and end date.
 const getData = async () => {
  const data = await getDataDate(startDateTime, endDateTime);
  setData(data);
  console.log(data);
 };

 //  labels are the entries in the data array with all index having object of dateTime
 const labels = data.length > 0 ? data.map((item) => item.dateTime) : [];

 //  data?.slice(0, showNumber).map((item) => (labels.push(item.date)));
 const dataChart = {
  labels,
  datasets: [
   {
    label: "Temperature",
    data: data.length > 0 ? data.map((item) => item.temperature) : [],
    borderColor: "rgb(255, 99, 132)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Ammonia",
    data: data.length > 0 ? data.map((item) => item.ammonia) : [],
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Chlorine",
    data: data.length > 0 ? data.map((item) => item.chlorine) : [],
    borderColor: "rgb(255, 205, 86)",
    backgroundColor: "rgba(255, 205, 86, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Nitrite",
    data: data.length > 0 ? data.map((item) => item.nirite) : [],
    borderColor: "rgb(75, 192, 192)",
    backgroundColor: "rgba(75, 192, 192, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Nitrate",
    data: data.length > 0 ? data.map((item) => item.nitrate) : [],
    borderColor: "rgb(153, 0, 0)",
    backgroundColor: "rgba(153, 102, 255, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Ph",
    data: data.length > 0 ? data.map((item) => item.ph) : [],
    borderColor: "rgb(255, 159, 64)",
    backgroundColor: "rgba(255, 159, 64, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Tds",
    data: data.length > 0 ? data.map((item) => item.tds) : [],
    borderColor: "rgb(255, 0, 132)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    yAxisID: "y1",
   },
   {
    label: "Dissolved Oxygen",
    data: data.length > 0 ? data.map((item) => item.dissolvedOxygen) : [],
    borderColor: "rgb(255, 99, 0)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    yAxisID: "y",
   },
   {
    label: "Orp",
    data: data.length > 0 ? data.map((item) => item.orp) : [],
    borderColor: "rgb(0, 99, 132)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    yAxisID: "y1",
   },
  ],
 };

 return (
  <>
   <div className="h-screen flex bg-gray-bg1 p-5 m-5">
    {/* A for loop for 6 sensors */}
    {/* heading */}
    <div>
     <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
      Plot
     </h4>

     {/* The date and time selections */}
     <div className="flex flex-row">
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Start Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={startDateTime}
        onChange={(e) => {
         setStartDateTime(e.target.value);
         getData();
        }}
       />
      </div>
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        End Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={endDateTime}
        onChange={(e) => {
         setEndDateTime(e.target.value);
         getData();
         console.log(data[0].dateTime);
        }}
       />
      </div>
     </div>

     {/* Chart */}
     <div className="plotPageplot">
      {startDateTime && endDateTime && data ? (
       <>
        <Line data={dataChart} options={options} />
        <p>
         <br />
         *Right y-axis is for TDS and ORP and left y-axis is for all other.
         <br />
         <br />
        </p>
       </>
      ) : (
       <h1>loading</h1>
      )}
     </div>
    </div>
   </div>
  </>
 );
}
