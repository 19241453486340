import './App.css';
import Navbar from './component/navbar';
import Routing from './routes'
function App() {
  return (
    <>
      <Navbar />
      <Routing />
    </>
  );
}

export default App;
