import React, { useEffect, useState } from "react";
import { getDataDate } from "../../../api/getData";

export default function Table() {
 const [showNumber, setShowNumber] = useState(10);
 const [sensor, setSensor] = useState([]);
 const [data, setData] = useState([]);
 const [startDateTime, setStartDateTime] = useState("");
 const [endDateTime, setEndDateTime] = useState("");

 const [temperature, setTemperature] = useState(true);
 const [ammonia, setAmmonia] = useState(false);
 const [chlorine, setChlorine] = useState(false);
 const [nitrite, setNitrite] = useState(false);
 const [nitrate, setNitrate] = useState(false);
 const [ph, setPh] = useState(false);
 const [turbidity, setTurbidity] = useState(false);
 const [dissolvedOxygen, setDissolvedOxygen] = useState(false);
 const [tds, setTds] = useState(false);
 const [orp, setOrp] = useState(false);

 //  get the data from the api using the start and end date.
 const getData = async () => {
  const data = await getDataDate(startDateTime, endDateTime);
  setData(data);
  console.log(data);
 };

 return (
  <>
   <div className="h-screen flex bg-gray-bg1 p-5 m-5">
    {/* A for loop for 6 sensors */}
    {/* heading */}
    <div>
     <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
      Sensor
     </h4>
     {/* The date and time selections */}
     <div className="flex flex-row">
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Start Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={startDateTime}
        onChange={(e) => {
         setStartDateTime(e.target.value);
         getData();
        }}
       />
      </div>
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        End Date & time
       </label>
       <input
        type="datetime-local"
        className="shadow appearance-none border rounded w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2"
        value={endDateTime}
        onChange={(e) => {
         setEndDateTime(e.target.value);
         getData();
        }}
       />
      </div>

      {/* select the temperature sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Temperature &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={temperature}
         onChange={(e) => {
          setTemperature(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the ammonia sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Ammonia &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={ammonia}
         onChange={(e) => {
          setAmmonia(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the chlorine sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Chlorine &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={chlorine}
         onChange={(e) => {
          setChlorine(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the nitrite sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Nitrite &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={nitrite}
         onChange={(e) => {
          setNitrite(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the nitrate sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Nitrate &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={nitrate}
         onChange={(e) => {
          setNitrate(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the ph sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Ph &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={ph}
         onChange={(e) => {
          setPh(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the tds sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Tds &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={tds}
         onChange={(e) => {
          setTds(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the dissolved oxygen sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Dissolved Oxygen &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={dissolvedOxygen}
         onChange={(e) => {
          setDissolvedOxygen(e.target.checked);
         }}
        />
       </label>
      </div>

      {/* select the orp sensor you want to show with the checkbox */}
      <div className="flex flex-col">
       <label className="text-gray-700 text-sm font-bold m-2 ml-2">
        Orp &nbsp;
        <input
         type="checkbox"
         className="focus:shadow-outline"
         checked={orp}
         onChange={(e) => {
          setOrp(e.target.checked);
         }}
        />
       </label>
      </div>
     </div>

     <div>
      <br />
      <label for="number">Choose a number of data to show : &nbsp;</label>
      <select
       name="number"
       id="number"
       className="border border-grey-light text-grey-darkest rounded-lg"
       onChange={(e) => {
        setShowNumber(e.target.value);
       }}
      >
       <option value="10">10</option>
       <option value="20">20</option>
       <option value="50">50</option>
       <option value="100">100</option>
       <option value="500">500</option>
      </select>
     </div>
     <br />
     {/* when no sensor is selected then show please select a sensor */}
     {temperature ||
     ammonia ||
     chlorine ||
     dissolvedOxygen ||
     nitrate ||
     nitrite ||
     orp ||
     ph ||
     tds ? (
      <></>
     ) : (
      <>
       <div class="flex items-center">
        <span>Please select at least one sensor</span>
       </div>
      </>
     )}

     {/* Table for the data */}
     <div class="bg-white shadow-md rounded my-6">
      <table class="min-w-max w-full table-auto">
       <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
         <th class="py-3 px-6 text-left">Date & Time</th>

         {temperature ? <th class="py-3 px-6 text-left">Temperature</th> : null}

         {/* show only when ammonia */}
         {ammonia ? <th class="py-3 px-6 text-left">Ammonia</th> : null}

         {/* show only when chlorine */}
         {chlorine ? <th class="py-3 px-6 text-left">Chlorine</th> : null}

         {dissolvedOxygen ? (
          <th class="py-3 px-6 text-left">Dissolved Oxygen</th>
         ) : null}

         {nitrate ? <th class="py-3 px-6 text-left">Nitrate</th> : null}

         {nitrite ? <th class="py-3 px-6 text-left">Nitrite</th> : null}

         {orp ? <th class="py-3 px-6 text-left">ORP</th> : null}

         {ph ? <th class="py-3 px-6 text-left">PH</th> : null}

         {tds ? <th class="py-3 px-6 text-left">TDS</th> : null}
        </tr>
       </thead>
       <tbody class="text-gray-600 text-sm font-light">
        {/* Show only if start date and endDate is not empty */}
        {startDateTime && endDateTime ? (
         <>
          {data?.slice(0, showNumber).map((item) => (
           <tr class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-6 text-left whitespace-nowrap">
             <div class="flex items-center">
              <span class="font-medium">{item.dateTime}</span>
             </div>
            </td>

            {temperature ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.temperature}</span>
              </div>
             </td>
            ) : null}
            {ammonia ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.ammonia}</span>
              </div>
             </td>
            ) : null}
            {chlorine ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.chlorine}</span>
              </div>
             </td>
            ) : null}
            {dissolvedOxygen ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.dissolvedOxygen}</span>
              </div>
             </td>
            ) : null}
            {nitrate ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.nitrate}</span>
              </div>
             </td>
            ) : null}
            {nitrite ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.nitrite}</span>
              </div>
             </td>
            ) : null}
            {orp ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.orp}</span>
              </div>
             </td>
            ) : null}
            {ph ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.ph}</span>
              </div>
             </td>
            ) : null}
            {tds ? (
             <td class="py-3 px-6 text-left">
              <div class="flex items-center">
               <span>{item.tds}</span>
              </div>
             </td>
            ) : null}
           </tr>
          ))}
         </>
        ) : (
         <div></div>
        )}
       </tbody>
      </table>
     </div>
    </div>
   </div>
  </>
 );
}
