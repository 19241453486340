import React, { useEffect } from "react";
import { GoogleLogout } from "react-google-login";

export default function Navbar() {
 const [isAuth, setAuth] = React.useState(false);
 const [sensorId, setSensorId] = React.useState(
  window.localStorage.getItem("sensorId")
 );
 const Logout = () => {
  window.localStorage.setItem("auth", false);
 };

 useEffect(() => {
  setAuth(window.localStorage.getItem("auth"));
 }, []);

 return (
  <>
   <ul className="flex bg-blue-light p-1">
    <li className="mr-6 p-2">
     <a className="text-blue hover:text-blue-800" href="/">
      Home
     </a>
    </li>
    {/* Authenticated Routes */}
    {isAuth && (
     <>
      <li className="mr-6 p-2">
       <a className="text-blue hover:text-blue-800" href="/sensor">
        Sensors
       </a>
      </li>
      
      <li className="mr-6 p-2 text-right">
       <a className="text-blue hover:text-blue-800" href="/sensor/plot">
        Plot
       </a>
      </li>
      <li className="mr-6 p-2">
       <a className="text-blue hover:text-blue-800" href="/sensor/table">
        Table
       </a>
      </li>
      <li className="mr-6 p-2">
       <a className="text-blue hover:text-blue-800" href="/sensor/message">
        Message
       </a>
      </li>
      <li className="mr-6 p-2">
       <a className="text-blue hover:text-blue-800" href="/sensor/prediction">
        Prediction
       </a>
      </li>

      {/* <li className="mr-6 p-2">
       <a
        className="text-blue hover:text-blue-800"
        href="/login"
        onClick={() => {
         Logout();
        }}
       >
        Logout
       </a>
      </li> */}
     </>
    )}

    {/* Unauthenticated Routes */}
    {/* {!isAuth && (
     <>
      <li className="mr-6 p-2">
       <a className="text-blue hover:text-blue-800" href="/login">
        Login
       </a>
      </li>
     </>
    )} */}

    {/* <li>
     <GoogleLogout
      clientId="312232375517-jj6vk0aeigmnhas9hv9qmd3kgdlhlik8.apps.googleusercontent.com"
      buttonText="Logout"
      onLogoutSuccess={OnSuccess}
     ></GoogleLogout>
    </li> */}
   </ul>
  </>
 );
}
